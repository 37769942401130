<template>
  <div class="userInfo">
    <!-- 顶部导航 -->
    <commonNavBar :title="'个人信息'"></commonNavBar>
    <div
      class="infoBox"
      v-watermark
    >
      <van-cell-group>
        <van-cell
          title="头像:"
          center
        >
          <template #default>
            <span class="userPic">
              {{ $store.state.user.profile.firstName || "--" }}
            </span>
          </template>
        </van-cell>
        <van-cell
          title="姓名:"
          :value="$store.state.user.profile.userName || '--'"
          center
        />
        <van-cell
          title="性别:"
          :value="$store.state.user.profile.gender || '--'"
          center
        />
        <van-cell
          title="生日:"
          :value="$store.state.user.profile.birthday | openYmdFormat"
          center
        />
        <van-cell
          title="单位:"
          :value="$store.state.user.profile.companyName || '--'"
          center
        />
        <van-cell
          title="部门:"
          :value="$store.state.user.profile.departmentName || '--'"
          center
        />
        <van-cell
          title="岗位:"
          :value="$store.state.user.profile.positionName || '--'"
          center
        />
        <van-cell
          title="手机:"
          :value="$store.state.user.profile.mobile || '--'"
          center
        >
        </van-cell>
        <van-cell
          title="短号:"
          :value="$store.state.user.profile.shortPhone || '--'"
          center
        >
        </van-cell>
        <van-cell
          title="邮箱:"
          :value="$store.state.user.profile.email || '--'"
          center
        >
        </van-cell>
        <van-cell
          center
          title="电子工作证:"
          v-if="['WLWBZ', 'YWB'].includes($store.state.user.profile.departmentCode
          )"
        >
          <template #default>
            <span
              style="color: #1990ff"
              @click="
                employeeCardHandler($store.state.user.profile.employeeCard, $store.state.user.profile.certificateValidityPeriodExpired)
                "
              v-if="$store.state.user.profile.employeeCard
              "
            >点击查看</span>
            <span v-else>--</span>
          </template>
        </van-cell>
        <van-cell
          title="证件有效期:"
          center
          v-if="['WLWBZ', 'YWB'].includes($store.state.user.profile.departmentCode
          )"
        >
          <div v-if="$store.state.user.profile.certificateValidityPeriodStart && $store.state.user.profile.certificateValidityPeriodEnd">
            {{ $store.state.user.profile.certificateValidityPeriodStart | openYmdFormat
            }}~{{ $store.state.user.profile.certificateValidityPeriodEnd | openYmdFormat }}
          </div>
          <div v-else>--</div>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
export default {
  name: 'userInfo',
  data() {
    return {
      isShowMoblieCheckDialog: false,
      isShowEmailCheckDialog: false,
      code: '',
      mobileTime: 0,
      emailTime: 0
    }
  },
  methods: {
    employeeCardHandler(val, flag) {
      if (flag) {
        this.$toast.fail({
          message: '当前工作证已过期，请联系管理员！',
          duration: 1000
        })
      } else {
        ImagePreview({
          images: [...val.map((item) => {
            return '/api/file/' + item.originalPath
          })],
          closeable: true
        })
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.userInfo {
  ::v-deep {
    .infoBox {
      height: calc(100vh - 46px);
      position: fixed;
      width: 100%;
      overflow: auto;

      .van-cell-group {
        .van-cell {
          .van-cell__value {
            flex: 2;
          }
        }
      }
    }

    .userPic {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      vertical-align: middle;
      display: inline-block;
      background-color: #1990ff;
      color: white;
      text-align: center;
      line-height: 50px;
      font-size: 20px;
    }

    .checked {
      color: #1990ff;
      text-decoration: underline;
      padding-left: 5px;
    }

    .van-dialog {
      border-radius: 0;
      width: 95%;

      .van-dialog__content {
        padding: 10px;

        .checkTitle {
          font-weight: 700;
          margin-bottom: 10px;
        }

        .checktext {
          font-size: 14px;
          color: #939393;
        }

        .checkCode {
          margin-top: 10px;

          .van-cell {
            padding: 0;

            .van-cell__title {
              margin-right: 0;

              span {
                font-size: 14px;
                font-weight: 700;
              }
            }

            .van-cell__value {
              border: 1px solid #d9d9d9;
              width: 100px;
              flex: none;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
